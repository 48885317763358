<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <base-loader v-if="loading"></base-loader>
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8">
                    <h3 class="mb-0">{{ $t("users.new user") }}</h3>
                  </div>
                </div>
              </div>
            </template>

            <form>
              <!-- Errors Handleing -->
              <div
                class="row bg-danger py-3 mb-3"
                v-if="validationErrors.length"
              >
                <div
                  class="col-md-6"
                  v-for="(error, index) in validationErrors"
                  :key="index"
                >
                  <span class="text-white mr-2 error-handling">{{
                    error
                  }}</span>
                </div>
              </div>
              <h6 class="heading-small text-muted mb-4">
                {{ $t("users.user information") }}
              </h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.name')"
                      :placeholder="$t('form.name')"
                      input-classes="form-control-alternative"
                      v-model="new_user.name"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="email"
                      alternative=""
                      :label="$t('form.email')"
                      :placeholder="$t('form.email')"
                      input-classes="form-control-alternative"
                      v-model="new_user.email"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      :label="$t('form.phone')"
                      :placeholder="$t('form.phone')"
                      input-classes="form-control-alternative"
                      v-model="new_user.phone"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      type="password"
                      alternative=""
                      :label="$t('form.password')"
                      :placeholder="$t('form.password')"
                      input-classes="form-control-alternative"
                      v-model="new_user.password"
                    />
                  </div>
                  <!-- <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.role")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="new_user.role"
                      >
                        <option value="" selected>
                          {{ $t("form.choose role") }}
                        </option>
                        <option
                          v-for="(role, index) in roles"
                          :key="index"
                          :value="role.id"
                        >
                          {{ role.name }}
                        </option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label class="form-control-label">{{
                        $t("form.gender")
                      }}</label>
                      <select
                        class="form-control form-control-alternative"
                        v-model="new_user.gender"
                      >
                        <option value="" selected>
                          {{ $t("form.choose gender") }}
                        </option>
                        <option value="male">
                          {{ $t("form.male") }}
                        </option>
                        <option value="female">
                          {{ $t("form.female") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        {{ $t("profile.image") }}
                      </label>
                      <input
                        type="file"
                        class="form-control form-control-alternative"
                        v-on:change="updateImage"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <input
                type="submit"
                class="btn btn-primary"
                :value="$t('form.add')"
                @click.prevent="addUser"
              />
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "new-user",
  data() {
    return {
      loading: false,
      new_user: {
        name: "",
        email: "",
        phone: "",
        password: "",
        gender: "",
        avatar: "",
      },
      validationErrors: [],
    };
  },
  created() {
    // this.getRoles("admin/administration/members/roles/index");
  },
  methods: {
    validation: function () {
      this.validationErrors = [];
      if (this.new_user.name && this.new_user.avatar) {
        return true;
      } else {
        if (!this.new_user.name) { 
          this.validationErrors.push(`${this.$t("validation.name required")}`);
        }
        if (!this.new_user.avatar) { 
          this.validationErrors.push(`${this.$t("validation.img required")}`);
        }
        return false;
      }
    },
    // Add User
    addUser: function () {
      if (this.validation() == false) {
        this.$swal({
          position: "center",
          icon: "error",
          title: `${this.$t("validation.faild")}`,
          showConfirmButton: false,
          timer: 1200,
        });
        return false;
      } else {
        this.loading = true;
        let formData = this.toFormData(this.new_user);
        axios
          .post("admin/administration/members/register", formData)
          .then((response) => {
            if (response.data.error) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
              });
            } else {
              if (response.data.status == 0) {
                this.validationErrors.push(response.data.message);
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: `${this.$t("validation.faild")}`,
                  showConfirmButton: false,
                  timer: 1200,
                });
              } else {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: `${this.$t("validation.success")}`,
                  text: response.data.message,
                  showConfirmButton: true,
                });
                this.$router.push({
                  name: "users",
                });
              }
            }
          })
          .catch(() => {
            if (!window.navigator.onLine) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: `${this.$t("validation.check network")}`,
              });
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    // Get Image to Update
    updateImage(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.new_user.avatar = files[0];
    },
    toFormData: function (obj) {
      var fd = new FormData();
      for (var i in obj) {
        fd.append(i, obj[i]);
      }
      return fd;
    },
  },
};
</script>
